import Page from '../../components/Page'
import { useHostedContentRoomAsset, useHostedContentRoomBootstrap } from '../../hooks/useHostedContentRoom'
import { useParams } from 'react-router'
import { AssetDisplayPivot } from '../../components/hosted-content-room/asset/display/AssetDisplayPivot.tsx'

export function HostedContentRoomAssetPage() {
  const { shortCode, assetShortCode } = useParams()
  const { data: contentRoom } = useHostedContentRoomBootstrap(shortCode)
  const { data: asset, isLoading, isError } = useHostedContentRoomAsset(shortCode!!, assetShortCode)
  if (isLoading || isError) {
    return <></>
  }
  return (
    <Page title={asset.name} suffix={contentRoom?.companyName ? `| ${contentRoom?.companyName}` : ''}>
      <AssetDisplayPivot asset={asset} />
    </Page>
  )
}


