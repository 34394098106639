import { HostedContentRoomAsset, HostedContentRoomAssetPreview } from '../../../../@types/hosted-content-room.ts'
import Image from '../../../Image.tsx'
import { srcForHostedContentRoomPreviewItem } from '../../../asset-search/images.tsx'
import { AssetPreviewType } from '../../../../@types/asset.ts'
import { Box, Stack, Typography } from '@mui/material'
import { useActivePreviewContext } from './context/ActivePreviewContext.tsx'
import { useRef } from 'react'
import { alpha } from '@mui/material/styles'

export function SidebarPreviewThumbnail({ asset, preview }: {
  asset: HostedContentRoomAsset,
  preview: HostedContentRoomAssetPreview
}) {
  const ref = useRef<HTMLElement | null>()
  const { activePreviewId, scrollToPreview } = useActivePreviewContext()
  const handleClick = () => scrollToPreview(preview.assetPreviewId)
  const imgSrc = srcForHostedContentRoomPreviewItem(asset.roomShortCode, asset.assetId, preview.assetPreviewId, AssetPreviewType.THUMBNAIL_SM)
  const isActive = activePreviewId == preview.assetPreviewId

  return (
    <Box ref={ref} >
      <Stack
        onClick={handleClick}
        sx={{
          cursor: "pointer"
        }}
      >
        <Box
          p={1}
          sx={{
            background: (theme) => isActive ? alpha(theme.palette.primary.main, .5) : "",
            borderRadius: 1
          }}
        >
          <Image src={imgSrc} backgroundType='contain' />
        </Box>

        <Box textAlign="center">
          <Typography variant='standard'>Page {preview.displayOrder + 1}</Typography>
        </Box>
      </Stack>
    </Box>

  )
}