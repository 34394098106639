import { HostedContentRoomAsset } from '../../../../@types/hosted-content-room.ts'
import { AppBar, Box, Drawer, IconButton, Stack, Toolbar, Tooltip, Typography } from '@mui/material'
import { AssetPreview } from '../../../../@types/asset.ts'
import Iconify from '../../../Iconify.tsx'
import { useState } from 'react'
import { useHostedContentRoomDownload } from '../../../../hooks/useDownload.tsx'
import { ActivePreviewProvider } from './context/ActivePreviewContext.tsx'
import { SidebarPreviewList } from './SidebarPreviewList.tsx'
import { DocumentPreviewSection } from './DocumentPreviewSection.tsx'

export function DefaultAssetMultiPageDisplay({ asset, previews }: {
  asset: HostedContentRoomAsset,
  previews: AssetPreview[]
}) {
  const { consumeAsset } = useHostedContentRoomDownload()
  const [drawerOpen, setDrawerOpen] = useState<boolean>(true)

  // handlers
  const handleDrawerToggle = () => setDrawerOpen((prev) => !prev)
  const handleDownload = () => consumeAsset(asset.roomShortCode, asset)

  // calculated props
  const drawerWidth = drawerOpen ? 240 : 0
  return (
    <Stack>
      <AppBar
        position='fixed'
        sx={{
          top: 65,
          height: 64,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar
          sx={{
            height: 1,
            px: '40px !important',
          }}
        >
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            sx={{ width: '100%' }}
          >
            <Box>
              <IconButton
                onClick={handleDrawerToggle}
                sx={{ ml: -1 }}
              >
                <Iconify icon='ph:list' />
              </IconButton>
            </Box>
            <Box textAlign='center'>
              <Typography variant='h2'>{asset.name}</Typography>
            </Box>
            <Box>
              <Tooltip title='Download'>
                <IconButton
                  onClick={handleDownload}
                >
                  <Iconify icon='ph:download' />
                </IconButton>
              </Tooltip>
            </Box>
          </Stack>
        </Toolbar>

      </AppBar>

      <ActivePreviewProvider>
        <Box sx={{ display: 'flex' }}>

          {/* sidebar */}
          <Drawer
            variant='persistent'
            anchor='left'
            open={drawerOpen}
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
              },
            }}
          >
            <Box mt={16}>
              <SidebarPreviewList asset={asset} previews={previews} />
            </Box>
          </Drawer>

          <DocumentPreviewSection asset={asset} previews={previews} />
        </Box>
      </ActivePreviewProvider>
    </Stack>
  )
}