import Page from '../../components/Page'
import { Paper, Stack, Typography } from '@mui/material'
import { m } from 'framer-motion'
import { varBounce } from '../../components/animate'
import Iconify from '../../components/Iconify'
import { HostedContentRoomModalLayout } from '../../layouts/hosted-content-room/HostedContentRoomModalLayout'


export function HostedContentRoom404() {
  return (
    <HostedContentRoomModalLayout>
      <Page title='Content Room Not Found'>
        <Paper
          elevation={2}
          sx={{
            p: 6,
            borderRadius: 2,
            textAlign: 'center',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <Iconify
            icon='eva:search-fill'
            sx={{
              height: 96,
              width: 96,
              position: 'absolute',
              top: -64,
              left: '50%',
              marginLeft: '-48px',
            }}
          />
          <Stack spacing={1}>
            <m.div variants={varBounce().in}>
              <Typography variant='h2' paragraph>
                Hmmm, we couldn't find that one...
              </Typography>
            </m.div>

            <m.div variants={varBounce().in}>
              <Typography variant='h4' paragraph>
                ...that <Typography color='primary' variant='h3' component='span'>Personal Content
                Room</Typography> may not exist!
              </Typography>
            </m.div>

            <m.div variants={varBounce().in}>
              <Typography sx={{ color: 'text.secondary' }}>
                If you're sure you have the correct link, you might need to reach out to the sender.
                It may have expired since it was originally sent to you.
              </Typography>
            </m.div>
          </Stack>
        </Paper>
      </Page>
    </HostedContentRoomModalLayout>
  )
}