import { SidebarPreviewThumbnail } from './SidebarPreviewThumbnail.tsx'
import { Stack } from '@mui/material'
import { AssetPreview } from '../../../../@types/asset.ts'
import { HostedContentRoomAsset } from '../../../../@types/hosted-content-room.ts'

type SidebarPreviewListProps = {
  asset: HostedContentRoomAsset;
  previews?: AssetPreview[];
}

export function SidebarPreviewList({ asset, previews }: SidebarPreviewListProps) {
  return (
    <Stack spacing={4} p={3}>
      {previews && previews.map(it => {
        return <SidebarPreviewThumbnail
          key={it.assetPreviewId}
          asset={asset}
          preview={it}
        />
      })}
    </Stack>
  )
}