import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  getHostedContentRoomAsset, getHostedContentRoomAssetPreviewMetadata, getHostedContentRoomAssetPreviews,
  getHostedContentRoomAssets,
  getHostedContentRoomBootstrap,
  loginToHostedContentRoom,
  setPCRAssetFavorite,
} from '../clients/HostedContentRoomClient'
import { GetHostedContentRoomBootstrapResponse } from '../@types/hosted-content-room'
import { AssetPreviewType } from '../@types/asset.ts'

const ONE_HOUR_IN_MS = 3_600_000

export function useHostedContentRoomBootstrap(shortCode: string | undefined) {
  return useQuery({
    enabled: Boolean(shortCode),
    queryKey: ['hosted-content-room-bootstrap', shortCode],
    queryFn: async () => getHostedContentRoomBootstrap(shortCode!!),
    staleTime: ONE_HOUR_IN_MS
  })
}

type HostedContentRoomLoginParams = {
  shortCode: string,
  password: string
}

export function useHostedContentRoomLogin() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (params: HostedContentRoomLoginParams) =>
      loginToHostedContentRoom(params.shortCode, params.password),
    onSuccess: async (data, { shortCode }) => {
      queryClient.setQueryData(
        ['hosted-content-room-bootstrap', shortCode],
        (oldData?: GetHostedContentRoomBootstrapResponse) => oldData ? { ...oldData, ...data } : oldData,
      )
    },
    // onError
  })
}

export function useHostedContentRoomAssets(shortCode: string){
  return useQuery({
    queryKey: ['hosted-content-room-assets', shortCode],
    queryFn: async () => getHostedContentRoomAssets(shortCode)
  })
}

export function useInfiniteContentRoomAssets(shortCode: string){
  return useInfiniteQuery({
    queryKey: ['hosted-content-room-assets', shortCode],
    queryFn: async ({pageParam: offset = 0}) => getHostedContentRoomAssets(shortCode, offset),
    getNextPageParam: (lastPage, _) => lastPage.offset + 100
  })
}

export function useHostedContentRoomAsset(roomShortCode: string, assetId: string | undefined){
  return useQuery({
    enabled: Boolean(assetId),
    queryKey: ['hosted-content-room', roomShortCode, 'assets', assetId],
    queryFn: async() => getHostedContentRoomAsset(roomShortCode, assetId!!)
  })
}

export function useHostedContentRoomAssetPreviews(roomShortCode: string, assetId: string | null){
  return useQuery({
    enabled: Boolean(assetId),
    queryKey: ['hosted-content-room', roomShortCode, 'assets', assetId, 'previews'],
    queryFn: async() => getHostedContentRoomAssetPreviews(roomShortCode, assetId!!)
  })
}

export function useHostedContentRoomAssetPreviewMetadata(
  roomShortCode: string,
  assetId: string,
  previewId: string,
  size: AssetPreviewType
){
  return useQuery({
    queryKey: ['hosted-content-room', roomShortCode, 'assets', assetId, 'previews', previewId, 'metadata'],
    queryFn: async() => getHostedContentRoomAssetPreviewMetadata(roomShortCode, assetId, previewId, size),
    staleTime: Infinity
  })
}

type SetPCRAssetFavoriteParams = {
  shortCode: string,
  assetId: string,
  isFavorite: boolean
}

export function useSetPCRAssetFavorite(){
  return useMutation({
    mutationFn: async (params: SetPCRAssetFavoriteParams) =>
      setPCRAssetFavorite(params.shortCode, params.assetId, params.isFavorite)
  })
}